@import "../assets/styles/main";
.ViewOptions {
    position: absolute;
    top: 8px;
    left: 8px;
    background: $white;
    width: 450px;
    max-height: calc(100vh - 16px);
    overflow: auto;
    box-shadow: 0 1px 3px 0 $light-black;
    opacity: 0;
    animation: fadeIn ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    z-index: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ViewOptions_open {
    max-height: calc(100vh - 16px);
    overflow: auto;
}

.viewOptions_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-shadow: 0 2px 6px 0 $light-black;
    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
}

.viewOptions_header_title {
    width: 70%;
}

.category_input {
    padding: 8px;
    input {
        display: block;
        width: 200px;
        padding: 0 8px;
        height: 24px;
        font-size: 14px;
        font-weight: 700;
        color: $black;
    }
    button {
        margin-top: 4px;
        padding: 8px;
        font-size: 14px;
        font-weight: 700;
        background: $white !important;
    }
    .sub_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sub_category_name {
        @extend .small;
        @extend .bold;
        display: flex;
        flex-grow: 2;
        align-items: center;
        padding: 4px;
        color: $black;
        cursor: pointer;
    }
    .sub_category_form {
        padding: 12px 0;
    }
    .sub_category_input {
        width: 212px;
        padding: 4px;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 4px;
    }
    .sub_category_cancel {
        padding: 4px;
        font-size: 12px;
        font-weight: 700;
        margin-right: 4px;
        color: $black;
        background-color: $white;
    }
    .sub_category_submit {
        @extend .sub_category_cancel;
        color: $white;
        background-color: $primary;
    }
    .sub_category_name_hover {
        background-color: $gray-2;
    }
    .sub_category_name:hover,
    .category_name:hover {
        background-color: $gray-2;
    }
    .sub_category_icon {
        display: flex;
        align-items: center;
        img {
            width: 16px;
            height: 16px;
            padding: 2px;
            margin-left: 8px;
            cursor: pointer;
            &:hover {
                background-color: $gray-2;
            }
        }
    }
}