.Filters {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.editor_container {
    height: 40vh!important;
}


/* Let Text Editor controls to overflow modal borders. */

.Modal {
    overflow: visible!important
}

.Modal_body {
    overflow: visible!important
}