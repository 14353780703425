
$primary:     #3f51b5;

$black:       #353c43;
$light-black: rgba(0, 0, 0, 0.1);
$gray-6:      #6e7072;
$gray-5:      #999da0;
$gray-4:      #ccced0;
$gray-3:      #e0e2e4;
$gray-2:      #ecedee;
$gray-1:      #f7f8f9;
$white:       #fff;

$danger:      #f93838;
$warning:     #f46b1e;
$success:     #43a047;
