@import url(//hello.myfonts.net/count/34d296);
@charset "UTF-8";
html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.h1 {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.h2 {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.h3 {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.h4 {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.h5 {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.large {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.small {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.bold {
  font-weight: 700; }

a {
  color: #3f51b5; }

/**
 * @license
 * MyFonts Webfont Build ID 3461782, 2017-10-09T08:34:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HarmoniaSansStd-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-regular-116608/
 *
 * Webfont: HarmoniaSansStd-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-bold-116608/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3461782
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright 2010 Monotype Imaging Inc. All rights reserved.
 *
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'HarmoniaSansStd';
  src: url(/static/media/34D296_0_0.b8017509.eot);
  src: url(/static/media/34D296_0_0.b8017509.eot?#iefix) format("embedded-opentype"), url(/static/media/34D296_0_0.dbb35763.woff2) format("woff2"), url(/static/media/34D296_0_0.41ded5df.woff) format("woff"), url(/static/media/34D296_0_0.f4928d0a.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'HarmoniaSansStd';
  src: url(/static/media/34D296_1_0.d306c105.eot);
  src: url(/static/media/34D296_1_0.d306c105.eot?#iefix) format("embedded-opentype"), url(/static/media/34D296_1_0.78a9958b.woff2) format("woff2"), url(/static/media/34D296_1_0.52bc61f7.woff) format("woff"), url(/static/media/34D296_1_0.878fb865.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  color: #353c43;
  font-family: 'HarmoniaSansStd', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.Filters {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.editor_container {
    height: 40vh!important;
}


/* Let Text Editor controls to overflow modal borders. */

.Modal {
    overflow: visible!important
}

.Modal_body {
    overflow: visible!important
}
html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.sub_category_h1__8cu7L {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.sub_category_h2__3ksMy {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.sub_category_h3__i1HtZ {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.sub_category_h4__23RWD {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.sub_category_h5__2Dako {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.sub_category_large__38foL {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.sub_category_small__3FcJx, .sub_category_sub_category_name__38WDb {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.sub_category_bold__UUDWN, .sub_category_sub_category_name__38WDb {
  font-weight: 700; }

a {
  color: #3f51b5; }

.sub_category_sub_category__18P4o {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.sub_category_sub_category_name__38WDb {
  display: flex;
  flex-grow: 2;
  align-items: center;
  padding: 4px 0px;
  color: #353c43;
  cursor: pointer; }

.sub_category_sub_category_form__boyV6 {
  padding: 12px 0; }

.sub_category_sub_category_input__s3-oo {
  width: 212px;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px; }

.sub_category_sub_category_cancel__2ReO5, .sub_category_sub_category_submit__SWska {
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
  color: #353c43;
  background-color: #fff; }

.sub_category_sub_category_submit__SWska {
  color: #fff;
  background-color: #3f51b5; }

.sub_category_sub_category_name_hover__26P3V {
  background-color: #ecedee; }

.sub_category_sub_category_name__38WDb:hover, .sub_category_category_name__1RZZB:hover {
  background-color: #ecedee; }

.sub_category_sub_category_icon__31PCT {
  display: flex;
  align-items: center; }
  .sub_category_sub_category_icon__31PCT img {
    width: 16px;
    height: 16px;
    padding: 2px;
    margin-left: 8px;
    cursor: pointer; }
    .sub_category_sub_category_icon__31PCT img:hover {
      background-color: #ecedee; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.category_h1__3PS8M {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.category_h2__2Up6x {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.category_h3__3EAz1 {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.category_h4__U_Mt9 {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.category_h5__1P8IC {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.category_large__zkR8o {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.category_small__22Bxl {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.category_bold__1DWSv {
  font-weight: 700; }

a {
  color: #3f51b5; }

.category_category_name__2qLMr {
  padding: 8px 8px 0 8px;
  font-weight: bold;
  line-height: 1.43;
  color: #353c43;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.category_category_title__1vt9U {
  cursor: pointer; }

.category_category_icons__1L-k5 {
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .category_category_icons__1L-k5 img {
    width: 20px;
    height: 20px;
    cursor: pointer; }

.category_category_input__34OzY {
  height: 24px;
  margin-right: 4px;
  font-weight: 700;
  color: #353c43;
  font-size: 14px;
  width: 212px;
  padding: 0 4px; }

.category_category_cancel__7IqAn, .category_category_submit__1RRXg {
  margin: 8px 0;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
  color: #353c43;
  background-color: #fff; }

.category_category_submit__1RRXg {
  color: #fff;
  background-color: #3f51b5; }

.category_sub_categories__QhRvr {
  padding: 0 8px; }

.category_open_category__Nbzqn {
  margin-top: 2px;
  padding-bottom: 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f8f9; }

.category_hide__383lA {
  display: none; }

.category_type__3V4m4 {
  font-size: 12px;
  color: #999da0;
  font-weight: 600;
  margin-top: 6px;
  text-transform: uppercase; }

.category_sub_category_form__RFfwf {
  padding: 12px 0; }
  .category_sub_category_form__RFfwf label {
    display: block; }

.category_sub_category_input__2UIbp {
  width: 212px;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px; }

.category_sub_category_cancel__1iM1u, .category_sub_category_submit__1eCmr {
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
  color: #353c43;
  background-color: #fff; }

.category_sub_category_submit__1eCmr {
  color: #fff;
  background-color: #3f51b5; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.categories_h1__ZMGZq {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.categories_h2__1dTQL {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.categories_h3__3KtMJ {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.categories_h4__1hpfl {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.categories_h5__1Z80I {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.categories_large__mW3uf {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.categories_small__YkUFr {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.categories_bold__3stjL {
  font-weight: 700; }

a {
  color: #3f51b5; }

.categories_Categories__3TbTW {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #fff;
  width: 240px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  -webkit-animation: categories_fadeIn__3RX84 ease-in;
          animation: categories_fadeIn__3RX84 ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  z-index: 300; }

@-webkit-keyframes categories_fadeIn__3RX84 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes categories_fadeIn__3RX84 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.categories_Categories_open__2ubMD {
  max-height: calc(100vh - 16px);
  padding-bottom: 16px;
  overflow: auto; }

.categories_categories_header__2H828 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }
  .categories_categories_header__2H828 img {
    cursor: pointer;
    width: 20px;
    height: 20px; }

.categories_categories_header_title__2yQrz {
  width: 70%; }

.categories_category_input__1LV4- {
  padding: 8px; }
  .categories_category_input__1LV4- input {
    display: block;
    width: 200px;
    padding: 0 8px;
    height: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #353c43; }
  .categories_category_input__1LV4- button {
    margin-top: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    background: #fff !important; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.h1 {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.h2 {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.h3 {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.h4 {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.h5 {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.large {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.small {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.bold {
  font-weight: 700; }

a {
  color: #3f51b5; }

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10000; }

.Overlay2 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 50000; }

.edit_subcategory_modal {
  width: 800px; }
  .edit_subcategory_modal img {
    cursor: pointer; }

.Modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #e0e2e4; }

.Modal_body {
  position: relative;
  max-height: 70vh;
  overflow: auto;
  padding: 8px;
  border-bottom: 1px solid #e0e2e4; }
  .Modal_body h3 {
    margin-bottom: 12px; }

.general_data {
  margin: 12px 0 24px 0;
  padding-bottom: 12px;
  min-width: 498px; }

.general_data_row {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .general_data_row input {
    height: 24px;
    margin-right: 8px;
    font-weight: 700;
    color: #353c43;
    font-size: 14px;
    width: 212px;
    padding: 0 4px; }

.editor_container {
  border: 1px solid #ecedee;
  padding: 8px;
  max-height: 50vh;
  overflow: auto; }

.Modal_footer {
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.Modal_cancel, .Modal_save {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  color: #353c43;
  background-color: #fff; }

.Modal_save {
  color: #fff;
  background-color: #3f51b5; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.sub_category_details_h1__7q2aP {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.sub_category_details_h2__1DOHN {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.sub_category_details_h3__68zaG {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.sub_category_details_h4__1Rz-P {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.sub_category_details_h5__8KkHl {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.sub_category_details_large__1v8pq {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.sub_category_details_small__2_2Xd {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.sub_category_details_bold__29h9r, .sub_category_details_field_title__PWQFv {
  font-weight: 700; }

a {
  color: #3f51b5; }

.sub_category_details_sub_category_details__2WBUQ {
  line-height: 1.3em;
  position: absolute;
  top: 8px;
  right: 256px;
  width: 336px;
  max-height: calc(100vh - 16px);
  margin-right: 8px;
  margin-left: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  -webkit-animation: sub_category_details_fadeIn__3Ggo0 ease-in;
          animation: sub_category_details_fadeIn__3Ggo0 ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  z-index: 300; }

@-webkit-keyframes sub_category_details_fadeIn__3Ggo0 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes sub_category_details_fadeIn__3Ggo0 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.sub_category_details_sub_category_details_header__2S3OE {
  margin-bottom: 2px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }
  .sub_category_details_sub_category_details_header__2S3OE img {
    cursor: pointer; }

.sub_category_details_sub_category_details_header__2S3OE img {
  width: 24px;
  height: 24px; }

.sub_category_details_sub_category_details_header__2S3OE h3 {
  width: 75%; }

.sub_category_details_sub_category_details_general__12QsI {
  padding: 8px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }

.sub_category_details_sub_category_details_general_field__3hnb0 {
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 14px;
  color: #353c43; }

.sub_category_details_field_title__PWQFv {
  padding-right: 8px; }

.sub_category_details_read_more__1_SV2 {
  display: flex;
  justify-content: flex-end; }

.sub_category_details_read_more__1_SV2 span {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #353c43;
  cursor: pointer; }

.sub_category_details_read_more__1_SV2 img {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-bottom: 2px; }

.sub_category_details_html_container__3ez1V {
  z-index: 300;
  box-sizing: border-box;
  max-height: calc(100vh - 16px); }

.sub_category_details_html_content__3uQBA {
  padding: 16px 8px;
  overflow: auto;
  max-height: 100%; }
  .sub_category_details_html_content__3uQBA p {
    margin-bottom: 12px; }
  .sub_category_details_html_content__3uQBA img {
    display: block;
    max-width: 90%;
    margin: 12px auto; }

.sub_category_details_header_box__pT_z7 {
  position: fixed;
  width: 336px;
  background: #fff; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.hotspot_h1__QKMVA {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.hotspot_h2__3Ok5b {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.hotspot_h3__d5INu {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.hotspot_h4__1b1AJ {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.hotspot_h5__h-xz2 {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.hotspot_large__Z6wnu {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.hotspot_small__2rUtl {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.hotspot_bold__2FaoU, .hotspot_Hotspot_title__NRhra, .hotspot_Hotspot_title_off__9obEq, .hotspot_Hotspot_title_on__3Nc7Z {
  font-weight: 700; }

a {
  color: #3f51b5; }

.hotspot_Hotspot__I5JAB {
  position: absolute;
  z-index: 95; }

.hotspot_Hotspot_shine__2vey6 {
  box-shadow: 0 0px 0px 0 #fff;
  -webkit-animation: hotspot_shine__3AzsQ ease-in;
          animation: hotspot_shine__3AzsQ ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  z-index: 256; }

@-webkit-keyframes hotspot_shine__3AzsQ {
  0% {
    box-shadow: 0 0px 0px 0 #fff; }
  50% {
    box-shadow: 2px 3px 24px 10px #fff; }
  100% {
    box-shadow: 0 0px 0px 0 #fff; } }

@keyframes hotspot_shine__3AzsQ {
  0% {
    box-shadow: 0 0px 0px 0 #fff; }
  50% {
    box-shadow: 2px 3px 24px 10px #fff; }
  100% {
    box-shadow: 0 0px 0px 0 #fff; } }

.hotspot_Hotspot_details__3f7Ja {
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer; }

.hotspot_Hotspot_icon__2fpXW {
  width: 24px;
  height: 24px;
  padding: 2px 2px 2px 2px;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 257; }

.hotspot_Hotspot_title__NRhra, .hotspot_Hotspot_title_off__9obEq, .hotspot_Hotspot_title_on__3Nc7Z {
  position: relative;
  background: white;
  line-height: 24px;
  padding: 0px 5px 4px 3px;
  -webkit-transition: left 0.23s cubic-bezier(0.74, 0.1, 0, 1);
  transition: left 0.23s cubic-bezier(0.74, 0.1, 0, 1);
  z-index: 256; }

.hotspot_Hotspot_title_off__9obEq {
  left: -100%; }

.hotspot_Hotspot_title_on__3Nc7Z {
  left: 0px; }

.hotspot_Hotspot_line__3vMte {
  width: 2px;
  height: 62px;
  background: #fff;
  opacity: 0.7;
  position: absolute;
  left: 11px; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.welcome_message_h1__2vKCj {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.welcome_message_h2__1QXeo {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.welcome_message_h3__3mgfk {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.welcome_message_h4__3O0Mv {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.welcome_message_h5__3oUjb {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.welcome_message_large__1cB8s {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.welcome_message_small__wRL9k, .welcome_message_WelcomeMessage_body__1WJkw .welcome_message_message_text__4kWZ6 {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.welcome_message_bold__1iQM1, .welcome_message_WelcomeMessage_footer__3GOU9 button {
  font-weight: 700; }

a {
  color: #3f51b5; }

.welcome_message_WelcomeMessage__yAlmy {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50; }

.welcome_message_WelcomeMessage_box__1XWyT {
  background-color: white;
  width: 465px; }

.welcome_message_WelcomeMessage_header__1g-mr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }
  .welcome_message_WelcomeMessage_header__1g-mr img {
    width: 16px;
    height: 16px;
    cursor: pointer; }

.welcome_message_WelcomeMessage_body__1WJkw {
  padding: 16px;
  padding-bottom: 0; }
  .welcome_message_WelcomeMessage_body__1WJkw img {
    display: block;
    width: 32px;
    height: 32px;
    margin: 10px auto; }
  .welcome_message_WelcomeMessage_body__1WJkw h4 {
    text-align: center; }

.welcome_message_WelcomeMessage_footer__3GOU9 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px; }
  .welcome_message_WelcomeMessage_footer__3GOU9 button {
    padding: 8px 24px;
    background-color: #3f51b5;
    color: #fff;
    outline: none; }

.welcome_message_hide__sI00B {
  display: none; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.credits_h1__IwKCT {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.credits_h2__3pDBS {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.credits_h3__H4_In {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.credits_h4__3R5ae {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.credits_h5__4NqHg {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.credits_large__1Doml {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.credits_small__26zMx {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.credits_bold__1kcAa {
  font-weight: 700; }

a {
  color: #3f51b5; }

.credits_Credits__1pE4V {
  position: absolute;
  width: 95%;
  bottom: 2%;
  z-index: 257;
  display: flex; }

.credits_Logo__24kqo {
  overflow: visible;
  float: left; }

.credits_Block__1n2sM {
  text-align: left;
  font-size: small;
  color: white;
  overflow: visible;
  width: 30%;
  float: left; }

html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px; }

h1,
.ViewOptionsPanel_h1__iFXHy {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700; }

h2,
.ViewOptionsPanel_h2__3179v {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700; }

h3,
.ViewOptionsPanel_h3__2FY5s {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700; }

h4,
.ViewOptionsPanel_h4__2b6X0 {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700; }

h5,
.ViewOptionsPanel_h5__J7Toq {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700; }

.ViewOptionsPanel_large__2qumI {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400; }

.ViewOptionsPanel_small__1z-wz, .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_name__FHXeH {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400; }

.ViewOptionsPanel_bold__2oE5J, .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_name__FHXeH {
  font-weight: 700; }

a {
  color: #3f51b5; }

.ViewOptionsPanel_ViewOptions__CHH2U {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #fff;
  width: 450px;
  max-height: calc(100vh - 16px);
  overflow: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  -webkit-animation: ViewOptionsPanel_fadeIn__2EkIj ease-in;
          animation: ViewOptionsPanel_fadeIn__2EkIj ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  z-index: 300; }

@-webkit-keyframes ViewOptionsPanel_fadeIn__2EkIj {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ViewOptionsPanel_fadeIn__2EkIj {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ViewOptionsPanel_ViewOptions_open__IBl5b {
  max-height: calc(100vh - 16px);
  overflow: auto; }

.ViewOptionsPanel_viewOptions_header__2ZyHE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }
  .ViewOptionsPanel_viewOptions_header__2ZyHE img {
    cursor: pointer;
    width: 20px;
    height: 20px; }

.ViewOptionsPanel_viewOptions_header_title__1_BJl {
  width: 70%; }

.ViewOptionsPanel_category_input__JP8to {
  padding: 8px; }
  .ViewOptionsPanel_category_input__JP8to input {
    display: block;
    width: 200px;
    padding: 0 8px;
    height: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #353c43; }
  .ViewOptionsPanel_category_input__JP8to button {
    margin-top: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    background: #fff !important; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category__Vpzor {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_name__FHXeH {
    display: flex;
    flex-grow: 2;
    align-items: center;
    padding: 4px;
    color: #353c43;
    cursor: pointer; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_form__Ww2z6 {
    padding: 12px 0; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_input__2Qg87 {
    width: 212px;
    padding: 4px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_cancel__2SVpE, .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_submit__1u1bq {
    padding: 4px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 4px;
    color: #353c43;
    background-color: #fff; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_submit__1u1bq {
    color: #fff;
    background-color: #3f51b5; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_name_hover__1TRnI {
    background-color: #ecedee; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_name__FHXeH:hover,
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_category_name__3OCOu:hover {
    background-color: #ecedee; }
  .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_icon__14wjJ {
    display: flex;
    align-items: center; }
    .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_icon__14wjJ img {
      width: 16px;
      height: 16px;
      padding: 2px;
      margin-left: 8px;
      cursor: pointer; }
      .ViewOptionsPanel_category_input__JP8to .ViewOptionsPanel_sub_category_icon__14wjJ img:hover {
        background-color: #ecedee; }

