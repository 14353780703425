html {
  font-size: 14px;
  font-family: 'HarmoniaSansStd';
  font-weight: 400;
  line-height: 24px;
}

h1,
.h1 {
  font-size: 1.85rem;
  line-height: 2.28rem;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 1.57rem;
  line-height: 2rem;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 1.28rem;
  line-height: 1.71rem;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 700;
}


h5,
.h5 {
  font-size: 1rem;
  line-height: 1.42rem;
  font-weight: 700;
}

.large {
  font-size: 1.14rem;
  line-height: 1.71rem;
  font-weight: 400;
}

.small {
  font-size: 0.85rem;
  line-height: 1.14rem;
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

a {
  color: $primary;
}
