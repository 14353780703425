@import "../assets/styles/main";
.Hotspot {
    position: absolute;
    z-index: 95;
}

.Hotspot_shine {
    box-shadow: 0 0px 0px 0 $white;
    animation: shine ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    z-index: 256;
}

@keyframes shine {
    0% {
        box-shadow: 0 0px 0px 0 $white;
    }
    50% {
        box-shadow: 2px 3px 24px 10px $white;
    }
    100% {
        box-shadow: 0 0px 0px 0 $white;
    }
}

.Hotspot_details {
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Hotspot_icon {
    width: 24px;
    height: 24px;
    padding: 2px 2px 2px 2px;
    background: white;
    box-shadow: 0 2px 6px 0 $light-black;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    z-index: 257;
}

.Hotspot_title {
    @extend .bold;
    position: relative;
    background: white;
    line-height: 24px;
    padding: 0px 5px 4px 3px;
    transition: left .23s cubic-bezier(.74, 0.1, 0, 1);
    z-index: 256;
}

.Hotspot_title_off {
    @extend .Hotspot_title;
    left: -100%;
}

.Hotspot_title_on {
    @extend .Hotspot_title;
    left: 0px;
}

.Hotspot_line {
    width: 2px;
    height: 62px;
    background: $white;
    opacity: 0.7;
    position: absolute;
    left: 11px;
}