@import "../assets/styles/main";
.sub_category_details {
    line-height: 1.3em;
    position: absolute;
    top: 8px;
    right: 256px;
    width: 336px;
    max-height: calc(100vh - 16px);
    margin-right: 8px;
    margin-left: 10px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 $light-black;
    opacity: 0;
    animation: fadeIn ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    z-index: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.sub_category_details_header {
    margin-bottom: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 6px 0 $light-black;
    img {
        cursor: pointer;
    }
}

.sub_category_details_header img {
    width: 24px;
    height: 24px;
}

.sub_category_details_header h3 {
    width: 75%;
}

.sub_category_details_general {
    padding: 8px;
    box-shadow: 0 2px 3px 0 $light-black;
}

.sub_category_details_general_field {
    display: flex;
    align-items: center;
    padding: 4px;
    font-size: 14px;
    color: $black;
}

.field_title {
    @extend .bold;
    padding-right: 8px;
}

.read_more {
    display: flex;
    justify-content: flex-end;
}

.read_more span {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: $black;
    cursor: pointer;
}

.read_more img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-bottom: 2px;
}

.html_container {
    z-index: 300;
    box-sizing: border-box;
    max-height: calc(100vh - 16px);
}

.html_content {
    padding: 16px 8px;
    overflow: auto;
    max-height: 100%;
    p {
        margin-bottom: 12px;
    }
    img {
        display: block;
        max-width: 90%;
        margin: 12px auto;
    }
}

.header_box {
    position: fixed;
    width: 336px;
    background: $white;
}